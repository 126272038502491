import { datadogRum } from "@datadog/browser-rum";
import "best-common-react/lib/styles/bcr.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import WrapperProvider from "./modules/WrapperProvider";

const { ENV: env, ADOBE_SCRIPT: trackingScript } = window.MLBBest.envVariables || {};

datadogRum.init({
  applicationId: "43118e9f-e730-41c3-8e68-81f99c18bf85",
  clientToken: "pubc158fabf26f9c10724784a32c1505dcd",
  site: "us5.datadoghq.com",
  service: "media-upload-tool",
  env: env,
  sampleRate: 100,
  premiumSampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

const container = document.getElementById("frontend-starter-app");
const root = createRoot(container);

const script = document.createElement("script");
script.src = trackingScript;
script.async = true;
document.head.appendChild(script);

root.render(
  <>
    <WrapperProvider>
      <App />
    </WrapperProvider>
  </>,
);
