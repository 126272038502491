export const RouteConstants = {
  WILDCARD: "*",
  LOGIN: "/login",
  LOGIN_CALLBACK: "/login/callback",
  BASE: "/",
  PACKAGES: "/package",
  GROUP_PACKAGE: "/packages/:group",
  PACKAGE: "/package/:packageId",
  HELP: "/help",
  ADMIN: {
    BASE: "/admin",
    USERS: "users",
    GROUPS: "groups",
    HELP: "help",
    PRESSBOX_DOWNLOADS: "pressbox/downloads",
  },
};
