import { Security } from "@okta/okta-react";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import HttpInterceptor from "../api/HttpInterceptor";
import { AppProvider } from "../contexts/AppContext";
import UnauthenticatedContexts from "../contexts/UnauthenticatedContexts";
import { oktaAuth } from "../contexts/UserContext";

const WrapperProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string): Promise<void> => {
    window.location.replace(originalUri);
  };

  return (
    <AppProvider>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <DndProvider backend={HTML5Backend}>
          <UnauthenticatedContexts>
            <HttpInterceptor>{children}</HttpInterceptor>
          </UnauthenticatedContexts>
        </DndProvider>
      </Security>
    </AppProvider>
  );
};

export default WrapperProvider;
