import {
  AlertsFromProvider,
  BCR,
  BCRSuspense,
  ErrorCatcher,
  getLocalStorageItem,
  Navy,
  setLocalStorageItem,
  useBreakpoints,
} from "best-common-react";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";
import Footer from "./components/navigation/Footer";
import Navigation from "./components/navigation/Navigation";
import { LocalStorageConstants } from "./constants/LocalStorageConstants";
import { RouteConstants } from "./constants/RouteConstants";
import { oktaAuth, useUser } from "./contexts/UserContext";
import { getFooterHeight } from "./util/FooterUtil";

const AuthenticatedApp = lazy(() => import("./modules/AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./modules/UnauthenticatedApp"));

type ContainerProps = {
  loggedIn: boolean;
};

const Container = styled.div<ContainerProps>`
  min-width: 100%;
  min-height: calc(100% - ${(props) => (props.loggedIn ? "69px" : "50px")});
  display: flex;
  flex-direction: column;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

type GlobalStyleProps = {
  footerHeight: number;
};

const GlobalStyles = createGlobalStyle<GlobalStyleProps>`
  html, body {
    height: calc(100% - ${(props) => props.footerHeight}px) !important;
  }
`;

const AppCheck = () => {
  const { loggedIn } = useUser();
  const { isMobile } = useBreakpoints();
  const [footerHeight, setFooterHeight] = useState<number>(85);

  useEffect(() => {
    if (isMobile) {
      setFooterHeight(0);
    } else {
      setFooterHeight(getFooterHeight() / 2);
    }
  }, [isMobile]);

  useEffect(() => {
    if (!loggedIn) {
      const location = window.location;
      if (
        !!location &&
        !location.pathname.includes(RouteConstants.LOGIN_CALLBACK) &&
        location.pathname !== "" &&
        location.pathname !== "/"
      ) {
        const path = location.pathname + location.search;
        setLocalStorageItem(LocalStorageConstants.ORIGINAL_URI, path);
        oktaAuth.setOriginalUri(path);
      } else {
        const originalUri = getLocalStorageItem<string>(LocalStorageConstants.ORIGINAL_URI);
        oktaAuth.setOriginalUri(originalUri);
      }
    }
  }, [loggedIn]);

  return (
    <Container loggedIn={loggedIn}>
      <GlobalStyles footerHeight={footerHeight} />
      {loggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Container>
  );
};

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Suspense fallback={<BCRSuspense />}>
      <BCR
        theme={{
          ...Navy,
          navigation: {
            ...Navy.navigation,
            horizontal: {
              ...Navy.navigation.horizontal,
              color: Navy.colors.white,
              centerLogo: {
                ...Navy.navigation.horizontal.centerLogo,
                height: "34px",
                width: "54px; margin-right: 6px;",
              },
            },
          },
          modal: {
            ...Navy.modal,
            backdropOpacity: 0.25,
          },
        }}
      >
        <ErrorCatcher appName="Media Upload Tool" contactEmail="mutsupport@mlb.com">
          <AlertsFromProvider />
          <BrowserRouter>
            <Navigation />
            <AppCheck />
          </BrowserRouter>
        </ErrorCatcher>
        <Footer />
      </BCR>
    </Suspense>
  );
};

export default App;
