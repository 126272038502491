import { AlertProvider, IndexedDbProvider, LoadingProvider } from "best-common-react";
import React from "react";
import { IndexDbStores, IndexedDbConstants } from "../constants/IndexDbConstants";
import { useApp } from "./AppContext";
import { MetadataProvider } from "./MetadataContext";
import { RolesProvider } from "./RolesContext";
import { UserProvider } from "./UserContext";

const UnauthenticatedContexts: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { setUseIndexedDb } = useApp();
  return (
    <LoadingProvider>
      <AlertProvider>
        <IndexedDbProvider
          dbName={IndexedDbConstants.DB_NAME}
          stores={IndexDbStores}
          version={IndexedDbConstants.VERSION}
          onError={(error) => {
            console.error(error);
            setUseIndexedDb(false);
          }}
        >
          <UserProvider>
            <RolesProvider>
              <MetadataProvider>{children}</MetadataProvider>
            </RolesProvider>
          </UserProvider>
        </IndexedDbProvider>
      </AlertProvider>
    </LoadingProvider>
  );
};

export default UnauthenticatedContexts;
