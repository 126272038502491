import React, { createContext, useContext, useState } from "react";

type AppContextType = {
  useIndexedDb: boolean;
  setUseIndexedDb: (value: boolean) => void;
};

const { USE_INDEXED_DB = true } = window.MLBBest.envVariables || {};

const AppContext = createContext<AppContextType | undefined>(undefined);

const AppProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [useIndexedDb, setUseIndexedDb] = useState<boolean>(USE_INDEXED_DB);

  return (
    <AppContext.Provider
      value={{
        useIndexedDb,
        setUseIndexedDb,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useApp = (): AppContextType => {
  const rolesContext: AppContextType = useContext<AppContextType>(AppContext);
  if (rolesContext === undefined) {
    throw new Error(`useApp must be used within a AppProvider`);
  }
  return rolesContext;
};

export { AppContext, AppProvider, useApp };
