import { AxiosError, AxiosResponse } from "axios";
import axiosRetry from "axios-retry";
import { AlertConstants, useAlerts } from "best-common-react";
import React, { useEffect } from "react";
import { useUser } from "../contexts/UserContext";
import { MutAlert } from "../types/Metadata";
import { getAuthTokens } from "../util/TokenUtil";
import MUTClient from "./MUTClient";

const HttpInterceptor: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { logout } = useUser();
  const { addAlert } = useAlerts();

  const createResponseAlerts = (alerts: MutAlert[]) => {
    alerts.forEach((alert: MutAlert) => {
      addAlert({
        type: !!alert.severity ? alert.severity : AlertConstants.TYPES.WARNING,
        text: !!alert.message ? alert.message : "There was an error please contact support",
      });
    });
  };

  const createInterceptors = () => {
    axiosRetry(MUTClient, {
      retries: 3, //attempts to retry
      retryCondition: (error) => {
        console.error("Error fetching from API will retry...");
        return !!error?.response && (error.response.status === 401 || error.response.status === 403);
      }, // only retry on unauthenticated errors to prevent retrying 500s unless that is wanted
      retryDelay: (retryCount) => retryCount * 1000, // wait 1 second between retrys
    });

    MUTClient.interceptors.request.use((config) => {
      const { accessToken = {}, idToken = {} } = getAuthTokens();

      if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
        config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
        config.headers["x-id-token"] = idToken.idToken;
      }
      return config;
    });

    MUTClient.interceptors.response.use(
      (resp: AxiosResponse) => {
        if (!resp) return null;
        if (resp.data) {
          if (resp.data.alerts) {
            createResponseAlerts(resp.data.alerts);
          }
        }
        return resp;
      },
      (err: AxiosError) => {
        if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
          logout();
          //@ts-ignore
        } else if (!!err?.response?.data?.alerts) {
          //@ts-ignore
          createResponseAlerts(err.response.data.alerts);
        } else if (!!err?.message) {
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: err.message,
          });
        } else {
          addAlert({
            type: AlertConstants.TYPES.DANGER,
            text: "There was an error with MUT please try again or contact support",
          });
        }
        return Promise.reject(err);
      },
    );
  };

  useEffect(() => {
    createInterceptors();
  }, []);

  return <>{children}</>;
};

export default HttpInterceptor;
