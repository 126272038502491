import React, { createContext, useContext, useEffect, useState } from "react";
import { ADMIN_ROLE, BASIC_ROLE, UserRole } from "../types/User";
import { useUser } from "./UserContext";

type RolesContextType = {
  isBasicUser: boolean;
  isAdminUser: boolean;
};

const RolesContext = createContext<RolesContextType | undefined>(undefined);

const RolesProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const { userInfo } = useUser();
  const [isBasicUser, setIsBasicUser] = useState<boolean>(false);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

  const checkForRoles = (roles: string[]) => {
    setIsBasicUser(roles.includes(BASIC_ROLE));
    setIsAdminUser(roles.includes(ADMIN_ROLE));
  };

  useEffect(() => {
    if (!!userInfo) {
      checkForRoles(userInfo.roles.map((role: UserRole) => role.role));
    }
  }, [userInfo]);

  return (
    <RolesContext.Provider
      value={{
        isBasicUser,
        isAdminUser,
      }}
    >
      {children}
    </RolesContext.Provider>
  );
};

const useRoles = (): RolesContextType => {
  const rolesContext: RolesContextType = useContext<RolesContextType>(RolesContext);
  if (rolesContext === undefined) {
    throw new Error(`useRoles must be used within a RolesProvider`);
  }
  return rolesContext;
};

export { RolesContext, RolesProvider, useRoles };
