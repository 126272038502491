const {
  OKTA_TESTING_DISABLEHTTPSCHECK = false,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  OKTA_REDIRECT_URI,
} = window.MLBBest.envVariables || {};
export const REDIRECT_URI = OKTA_REDIRECT_URI;

export default {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    tokenManager: {
      expireEarlySeconds: 60,
    },
  },
};
