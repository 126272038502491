import { DataTableDataType } from "best-common-react";
import { GroupDTO } from "./Metadata";

export type UserRoles = "Basic" | "Admin";

export const BASIC_ROLE: UserRoles = "Basic";
export const ADMIN_ROLE: UserRoles = "Admin";

export type UserRole = {
  userRoleId: number;
  role: UserRoles;
};

export type MutUserInfo = {
  appUserId: number;
  oktaId: string;
  email: string;
  firstName: string;
  lastName: string;
  defaultGroup: GroupDTO;
  roles: UserRole[];
  groups: GroupDTO[];
};

export type UserRoleMappingDTO = {
  userRoleMappingsId?: number;
  appUserId?: number;
  userRole: UserRole;
};

export type UserGroupMappingDTO = {
  userGroupMappingsId?: number;
  appUserId?: number;
  group: GroupDTO;
};

export type AppUserDTO = DataTableDataType & {
  appUserId?: number;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  defaultGroup: GroupDTO;
  userRoleMappings: UserRoleMappingDTO[];
  userGroups: GroupDTO[];
  userEmailSubscriptions: UserEmailSubscriptionDTO[];
};

export type BulkAppUserDTO = DataTableDataType & {
  email: string;
  firstName: string;
  lastName: string;
  defaultGroupId: number;
  roleIds: number[];
  groupIds: number[];
  valid?: boolean;
};

export type BulkAppUserUploadDTO = {
  users: BulkAppUserDTO[];
};

export type UserEmailSubscriptionDTO = {
  uploadTypeId?: number;
  appUserId?: number;
  userEmailSubscriptionId?: number;
};

export type UserQuery = {
  searchText?: string;
  groupId?: number;
};
