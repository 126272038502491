import { getLocalStorageItem, isBefore, setLocalStorageItem, sort } from "best-common-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getAllDownloadTypes,
  getAllGroups,
  getCacheRefreshTime,
  getDugoutFileTypes,
  getDugoutStatsTeams,
  getUploadTypes,
  getUserRoles,
} from "../api/MUTApi";
import { LocalStorageConstants } from "../constants/LocalStorageConstants";
import { CacheRefreshTimeDTO, DugoutFileType, GroupDTO } from "../types/Metadata";
import { PressboxDownloadTypeDTO } from "../types/Pressbox";
import { UploadType } from "../types/Uploads";
import { UserRole } from "../types/User";
import { useUser } from "./UserContext";

type MetadataContextType = {
  groups: GroupDTO[];
  uploadTypes: UploadType[];
  userRoles: UserRole[];
  pressboxDownloadTypes: PressboxDownloadTypeDTO[];
  fetchDownloadTypes: () => void;
  refreshMetadata: (value: boolean) => void;
  statsTeams: GroupDTO[];
  dugoutFileTypes: DugoutFileType[];
};

const MetadataContext = createContext<MetadataContextType | undefined>(undefined);

const MetadataProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loggedIn } = useUser();
  const [cacheTime, setCacheTime] = useState<Date | undefined>(undefined);
  const [groups, setGroups] = useState<GroupDTO[]>([]);
  const [uploadTypes, setUploadTypes] = useState<UploadType[]>([]);
  const [userRoles, setUserRoles] = useState<UserRole[]>([]);
  const [pressboxDownloadTypes, setPressboxDownloadTypes] = useState<PressboxDownloadTypeDTO[]>([]);
  const [dugoutFileTypes, setDugoutFileTypes] = useState<DugoutFileType[]>([]);
  const [statsTeams, setStatsTeams] = useState<GroupDTO[]>([]);

  const sortExceptions: { [name: string]: number } = {};
  sortExceptions["Major League Baseball"] = 1;

  const fetchGroups = async (refresh: boolean) => {
    const localStorage: GroupDTO[] | undefined = getLocalStorageItem<GroupDTO[]>(LocalStorageConstants.GROUPS);
    if (localStorage && !refresh) {
      setGroups(localStorage);
    } else {
      try {
        const result: GroupDTO[] = await getAllGroups();
        result.sort((a, b) => {
          if (sortExceptions[a.groupName] && sortExceptions[b.groupName]) {
            return sortExceptions[a.groupName] - sortExceptions[b.groupName];
          } else if (sortExceptions[a.groupName] || sortExceptions[b.groupName]) {
            return 1;
          } else {
            return a.groupName.localeCompare(b.groupName);
          }
        });
        setLocalStorageItem(LocalStorageConstants.GROUPS, result);
        setGroups(result);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const fetchUploadTypes = async (refresh: boolean) => {
    const localStorage: UploadType[] | undefined = getLocalStorageItem<UploadType[]>(
      LocalStorageConstants.UPLOAD_TYPES,
    );
    if (localStorage && !refresh) {
      setUploadTypes(localStorage);
    } else {
      try {
        const result: UploadType[] = await getUploadTypes();
        const sorted: UploadType[] = sort(result, "uploadTypeId", 1);
        setUploadTypes(sorted);
        setLocalStorageItem(LocalStorageConstants.UPLOAD_TYPES, sorted);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const fetchUserRoles = async (refresh: boolean) => {
    const localStorage: UserRole[] | undefined = getLocalStorageItem<UserRole[]>(LocalStorageConstants.USER_ROLES);
    if (localStorage && !refresh) {
      setUserRoles(localStorage);
    } else {
      try {
        const result: UserRole[] = await getUserRoles();
        const sorted: UserRole[] = sort(result, "userRoleId", 1);
        setUserRoles(sorted);
        setLocalStorageItem(LocalStorageConstants.USER_ROLES, sorted);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const fetchStatsTeams = async () => {
    const data: GroupDTO[] = await getDugoutStatsTeams();
    setStatsTeams(data);
  };

  const fetchDugoutTypes = async () => {
    const data: DugoutFileType[] = await getDugoutFileTypes();
    setDugoutFileTypes(data);
  };

  const fetchDownloadTypes = async () => {
    const data: PressboxDownloadTypeDTO[] = await getAllDownloadTypes();
    setPressboxDownloadTypes(data);
  };

  const refreshMetadata = (refresh: boolean): void => {
    fetchDownloadTypes();
    fetchGroups(refresh);
    fetchUploadTypes(refresh);
    fetchUserRoles(refresh);
    fetchDugoutTypes();
    fetchStatsTeams();
  };

  useEffect(() => {
    if (cacheTime) {
      const lastCacheTime: string = getLocalStorageItem<string>(LocalStorageConstants.LAST_CACHE_TIME);
      const refresh = !lastCacheTime || isBefore(new Date(lastCacheTime), cacheTime);
      if (refresh) {
        setLocalStorageItem(LocalStorageConstants.LAST_CACHE_TIME, cacheTime);
      }
      refreshMetadata(refresh);
    }
  }, [cacheTime]);

  const getCacheRefresh = async () => {
    const data: CacheRefreshTimeDTO = await getCacheRefreshTime();
    if (data) {
      setCacheTime(new Date(data.cacheTime));
    }
  };

  useEffect(() => {
    if (!!loggedIn) {
      getCacheRefresh();
    }
  }, [loggedIn]);

  return (
    <MetadataContext.Provider
      value={{
        groups,
        uploadTypes,
        userRoles,
        pressboxDownloadTypes,
        statsTeams,
        dugoutFileTypes,
        fetchDownloadTypes,
        refreshMetadata,
      }}
    >
      {children}
    </MetadataContext.Provider>
  );
};

const useMetadata = (): MetadataContextType => {
  const metadataContext = useContext<MetadataContextType | undefined>(MetadataContext);
  if (metadataContext === undefined) {
    throw new Error(`useMetadata must be used within a MetadataProvider`);
  }
  return metadataContext;
};

export { useMetadata, MetadataProvider };
